// If you want to override variables do it here
@import "variables";

@import "~react-responsive-select/dist/react-responsive-select.css";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
