.react-tel-input .form-control
{
    width: 100% !important;
    height: 15px !important;
}

.modal-confirm {		
    color: #636363;
    width: 325px;
    z-index: 999999;
}

@media only screen and (max-width: 768px) {
    .modal-confirm {		
        color: #636363;
        width: auto;
        margin-right: 1.5rem;
        
    }
}

@media only screen and (min-width: 767px) {
    .modal-confirm-md {		
        color: #636363;
        width: 500px;
    }
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}
.modal-confirm .modal-header {
    border-bottom: none;   
    position: relative;
    justify-content: center;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}
.modal-confirm .form-control, .modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px; 
}
.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}	
.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}	
.modal-confirm .icon-box {
    color: #fff;		
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm .icon-box .danger {
    background: red !important;
}

.modal-confirm .danger {
    background: red !important;
}

.model-confirm-danger
{
    background: red !important;
}




.modal-confirm .icon-box .warning {
    background: #f9b115 !important;
}

.modal-confirm .warning {
    background: #f9b115 !important;
}

.model-confirm-warning
{
    background: #f9b115 !important;
}

.modal-confirm .icon-box .info {
    background:#39f !important;
}

.modal-confirm .info {
    background:#39f !important;
}

.model-confirm-info
{
    background: #39f !important;
}

.modal-confirm .icon-box .primary {
    background: #321fdb !important;
}

.modal-confirm .primary {
    background:#321fdb !important;
}

.model-confirm-primary
{
    background: #321fdb !important;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}
.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    /* background: #82ce34; */
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
    /* background: #6fb32b; */
    outline: none;
}






/* Google Autocomple */

/* .location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
} */

.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  position: absolute;
  z-index: 1000;
  border-radius: 0 0 2px 2px;
}

.suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
  }
  
  .suggestion-item--active {
    background-color: green;
  }

  .suggestion-item:focus {
    padding: 8px;
    text-align: left;
    background-color: red;
    cursor: pointer;
  }

  .react-datepicker-wrapper {
width: 100%;
  }

  .rrs__label {
    border-color: hsl(0,0%,80%);
  }


  .dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .invite-dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

   /*
*
* ==========================================
* Scroll Button or Arrow
* ==========================================
*
*/

.scrollTop {
    position: fixed; 
    width: 100%;
    bottom: 30px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }
  
  .scrollTop:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.prospect-list-mobile::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .prospect-list-mobile {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .btn-drawer-header {
    background-color: transparent;
    border: transparent;
    padding: 10px;
    float: right;
  }

  .drawer-header {
    padding: 5px;
  }

  .activate-attendance{
    max-width: 400px;
    max-height: 400px;
    margin-left: auto; 
    margin-right: auto;

    .navigation {
        /* @apply fixed bottom-0 z-10 w-full p-4; */
        position: fixed;
        bottom: calc(100vh - 400px);
        z-index: 10;
        width: 100%;
        padding: 1rem;
    
        .navigation-wrapper {
            /* @apply flex items-center max-w-lg mx-auto; */
            display: flex;
            justify-content: space-between;            
            padding: 10px;
            max-width: 32rem;
        }
        button {
            /* @apply btn text-white;
    
            span:not(.loading) {
                @apply sr-only lg:not-sr-only;
            } */
        }
    }
      
  }
 


 