// Here you can add other styles
a {
    color: #20a8d8;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #167495;
}
.c-app {
    --blue: red;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #f86c6b;
    --orange: #f8cb00;
    --yellow: #ffc107;
    --green: #4dbd74;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #73818f;
    --gray-dark: #2f353a;
    --light-blue: #63c2de;
    --primary: #20a8d8;
    --secondary: #c8ced3;
    --success: #4dbd74;
    --info: #63c2de;
    --warning: #ffc107;
    --danger: #f86c6b;
    --light: #f0f3f5;
    --dark: #2f353a;
}

.c-main {
    padding-top: 0rem !important;
}

.c-sidebar .c-sidebar-brand {
    color: #fff;
    background: white;
}

.c-sidebar {
    color: #fff;
    background: #2f353a;
}

.c-sidebar {
    width: 210px;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    // color: rgba(255, 255, 255, 0.8);
    color: #fff;
    background: transparent;
}

.c-sidebar .c-sidebar-nav-title {
    // color: rgba(255, 255, 255, 0.6);
    color: #e4e7ea;
}

// .sidebar .nav-title {
//     padding: .75rem 1rem;
//     font-size: 80%;
//     font-weight: 700;
//     color: #e4e7ea;
//     text-transform: uppercase;
// }

@media (hover: hover), not all {
    .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: #fff;
        background: #20a8d8;
    }
}

@media (min-width: 992px)
{
html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 210px;
}
}

.dropdown-item {
    position: relative;
    // padding: 10px 20px;
    // border-bottom: 1px solid #c8ced3;
}

.dropdown-item:last-child {
    border-bottom: 0;
}
.dropdown-item:last-child {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px);
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none !important; 
}


.settings-nav-title {
    font-size: 0.875rem;
}

